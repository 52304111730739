import { useState, useEffect, useContext } from "react";
import { createContext } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "./AuthContext";

const BudgetContext = createContext();

export const BudgetContextProvider = ({ children }) => {
  const { currentUser, onDemo } = useContext(AuthContext);
  const [budgetData, setBudgetData] = useState([]);
  const [budgetDataAll, setBudgetDataAll] = useState([]);
  const currentYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    let list = [];
    let unsubscribed = false;
    let dataWithTotal = [];

    // ---------------- demo mode only
    if (!currentUser && onDemo) {
      import("./dummyData.js").then((module) => {
        module.dummyBudget.map((categoryItem) => {
          const totalAmount = Object.keys(categoryItem.amount).reduce(
            (acc, key) => {
              return acc + categoryItem.amount[key];
            },
            0
          );
          dataWithTotal.push({ ...categoryItem, totalAmount: totalAmount });
        });
        setBudgetDataAll(dataWithTotal);
        setBudgetData(dataWithTotal.filter((item) => item.year === year));
      });

      return; //exit function
    } // ---------------- demo mode only

    const fetchData = async () => {
      const q = query(
        collection(db, "budget"),
        where("userId", "==", currentUser.uid)
      );
      const res = await getDocs(q);

      if (!unsubscribed) {
        res.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        // creating a totalAmount:amount for each category
        list.map((categoryItem) => {
          const totalAmount = Object.keys(categoryItem.amount).reduce(
            (acc, key) => {
              return acc + categoryItem.amount[key];
            },
            0
          );
          dataWithTotal.push({ ...categoryItem, totalAmount: totalAmount });
        });
        setBudgetDataAll(dataWithTotal);
        setBudgetData(dataWithTotal.filter((item) => item.year === year));
      }
    };

    // --------------for creating a field only ---------------
    // const updateTransactionsField = async () => {
    // const q = query(collection(db, "transactions"))
    // const res = await getDocs(q)

    // res.forEach(async(document) => {
    //     const docRef = document.id
    //     await updateDoc(doc(db, "transactions", docRef), {"type":"expense"})
    // })
    // }

    // updateTransactionsField()
    //---------end function: updateTransactionsField -------------------------------

    fetchData();

    return () => {
      unsubscribed = true;
    };
  }, []);

  const changeYear = (newYear) => {
    setYear((prevState) => newYear);
    setBudgetData(budgetDataAll.filter((item) => item.year === newYear));
  };

  const editBudgetCategory = async (oldCategoryLabel, newCategoryLabel) => {
    if (currentUser && !onDemo) {
      try {
        let operationCounter = 0;
        let batch = writeBatch(db);
        const budgetQuery = query(
          collection(db, "budget"),
          where("userId", "==", currentUser.uid),
          where("category", "==", oldCategoryLabel)
        );
        const budgetSnapshotArray = await getDocs(budgetQuery);

        budgetSnapshotArray.forEach(async (document) => {
          const budgetDocData = document.data();
          batch.update(document.ref, { category: newCategoryLabel });
          operationCounter += 1;

          if ((operationCounter + 1) % 499 == 0) {
            await batch.commit();
            batch = writeBatch(db);
          }
        });

        // final commit
        if (!(operationCounter % 499) == 0) {
          await batch.commit();
        }
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setBudgetData((prevState) =>
      [...prevState].map((item) => {
        if (item.category === oldCategoryLabel) {
          return { ...item, category: newCategoryLabel };
        }
        return { ...item };
      })
    );
  };

  const deleteBudgetCategory = async (categoryLabel) => {
    if (currentUser && !onDemo) {
      try {
        let operationCounter = 0;
        let batch = writeBatch(db);
        const budgetQuery = query(
          collection(db, "budget"),
          where("userId", "==", currentUser.uid),
          where("category", "==", categoryLabel)
        );
        const budgetSnapshotArray = await getDocs(budgetQuery);

        budgetSnapshotArray.forEach(async (document) => {
          const budgetDocData = document.data();
          batch.delete(document.ref, budgetDocData);
          operationCounter += 1;

          if ((operationCounter + 1) % 499 == 0) {
            await batch.commit();
            batch = writeBatch(db);
          }
        });

        // final commit
        if (!(operationCounter % 499) == 0) {
          await batch.commit();
        }
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setBudgetData((prevState) =>
      [...prevState].filter((item) => item.category !== categoryLabel)
    );
    setBudgetDataAll((prevState) =>
      [...prevState].filter((item) => item.category !== categoryLabel)
    );
  };
  return (
    <BudgetContext.Provider
      value={{
        budgetData,
        budgetDataAll,
        setBudgetData,
        changeYear,
        year,
        setBudgetDataAll,
        deleteBudgetCategory,
        editBudgetCategory,
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
};

export default BudgetContext;
