export const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
export const getActualTotalFromArray = (
  array,
  month,
  year,
  periodicDisplay
) => {
  const monthIndex = months.indexOf(month);
  const filterDateBegMonth = new Date(year, monthIndex, 1);
  const filterDateEndMonth = new Date(year, monthIndex + 1, 0);
  const filterDateBegYear = new Date(year, 0, 1);
  const filterDateEndYear = new Date(year, 11, 31);

  const actualTotal = array.reduce((totalAmount, item) => {
    const itemDate = new Date(
      new Date(item.date.seconds * 1000).setHours(0, 0, 0, 0)
    );
    if (periodicDisplay === "monthly") {
      if (itemDate >= filterDateBegMonth && itemDate <= filterDateEndMonth) {
        const amount = parseFloat(item.amount);
        return (totalAmount += amount);
      } else {
        return totalAmount;
      }
    } else if (periodicDisplay === "yearly") {
      if (itemDate >= filterDateBegYear && itemDate <= filterDateEndYear) {
        const amount = parseFloat(item.amount);
        return (totalAmount += amount);
      } else {
        return totalAmount;
      }
    }
  }, 0);
  return actualTotal;
};

export const getValueFromString = (stringDigit) => {
  return parseFloat(stringDigit.replace(/,/gi, ""));
};

export const exportTransactionsToCSV = (type, data, accounts) => {
  const counterParty = type === "expense" ? "vendor" : "source";
  let csvContent = `date,account,category,${counterParty},description,amount\n`;
  csvContent += data
    .map((item) => {
      const dateFormatted = new Date(
        item.date.seconds * 1000
      ).toLocaleDateString(undefined, { timeZone: "UTC" });
      const exportContent = {
        date: dateFormatted,
        account: accounts.find((account) => account.id === item.accountId)
          ?.name,
        category: item.category,
        [counterParty]: item.vendor,
        description: item.description,
        amount: item.amount,
      };
      return Object.values(exportContent)
        .map(String) // convert every value to String
        .map((v) => v.replaceAll('"', '""')) // escape double colons
        .map((v) => `"${v}"`) // quote it
        .join(","); // comma-separated
    })
    .join("\r\n");

  var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", "my_data.csv");
  pom.click();
};

// convert color darker or brighter
export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export function convertDateToUTC(date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function filterYearTransactions(transactions, begYear, endYear) {
  const filteredTransactions = transactions.filter((item) => {
    const itemDate = new Date(item.date.seconds * 1000);
    return itemDate.getFullYear() === endYear.getFullYear();
  });

  return filteredTransactions;
}

export function groupTransactions(transactions, months) {
  const groupedTransactions = transactions.reduce(
    (obj, item) => {
      const itemCategory = item.category;
      const itemDate = new Date(item.date.seconds * 1000);
      // create "yearTotal: 0" object
      if (!obj["yearTotal"]) {
        obj["yearTotal"] = 0;
      }
      // create "categoryTotal:{transportation:0}" object
      if (!obj.categoryTotal[item.category]) {
        obj.categoryTotal[item.category] = 0;
      }
      // create "january:{}, february:{}" objects
      const itemMonth = months[itemDate.getMonth()];
      if (!obj[itemMonth]) {
        obj[itemMonth] = {
          month: itemMonth,
          totalMonth: 0,
          category: {},
        };
      }
      // create "january:{transportation:{categoryTotal:0, categoryItems:[]}}" object
      if (!obj[itemMonth].category[itemCategory]) {
        obj[itemMonth].category[item.category] = {
          categoryTotal: 0,
          categoryItems: [],
        };
      }
      obj["yearTotal"] += item.amount;
      obj.categoryTotal[item.category] += item.amount;
      obj[itemMonth].totalMonth += item.amount;
      obj[itemMonth].category[item.category].categoryTotal += item.amount;
      obj[itemMonth].category[item.category].categoryItems.push(item);

      return obj;
    },
    { yearTotal: 0, categoryTotal: {} }
  );

  return groupedTransactions;
}

export function getNextBillingDate(currentBillingDate, frequency, billingDay) {
  let nextBillingDate = new Date(currentBillingDate);
  switch (frequency) {
    case "Weekly":
      //set next billing period
      nextBillingDate.setDate(currentBillingDate.getDate() + 7);
      break;

    case "Monthly":
      //set next billing period
      const nextMonthEnd = new Date(
        new Date(
          currentBillingDate.getFullYear(),
          currentBillingDate.getMonth() + 2,
          1
        ).setDate(0)
      ); //jan 31, feb 28, etc..
      nextBillingDate = new Date(
        Math.min(
          new Date(
            currentBillingDate.getFullYear(),
            currentBillingDate.getMonth() + 1,
            billingDay
          ),
          nextMonthEnd
        )
      ); // when billing date > 30 or > 28 for feb get last day of the month
      break;
    case "Quarterly":
      //set next billing period
      const nextQuarterEnd = new Date(
        new Date(
          currentBillingDate.getFullYear(),
          currentBillingDate.getMonth() + 5,
          1
        ).setDate(0)
      ); //+4 months get and end date of the month
      nextBillingDate = new Date(
        Math.min(
          new Date(
            currentBillingDate.getFullYear(),
            currentBillingDate.getMonth() + 4,
            billingDay
          ),
          nextQuarterEnd
        )
      ); // when billing date > 30 or > 28 for feb get last day of the month
      break;
    case "Annual":
      //set next billing period
      const nextYearEnd = new Date(
        new Date(
          currentBillingDate.getFullYear(),
          currentBillingDate.getMonth() + 13,
          1
        ).setDate(0)
      ); //+4 months get and end date of the month
      nextBillingDate = new Date(
        Math.min(
          new Date(
            currentBillingDate.getFullYear(),
            currentBillingDate.getMonth() + 12,
            billingDay
          ),
          nextYearEnd
        )
      ); // for leap years
      break;
    default:
      //safety measure to get outside of loop
      nextBillingDate.setDate(new Date().getDate + 1);
      break;
  } // -----end switch

  return nextBillingDate;
}

export function getPreviousBillingDate(
  currentBillingDate,
  frequency,
  billingDay
) {
  let nextBillingDate = new Date(currentBillingDate);
  switch (frequency) {
    case "Weekly":
      //set next billing period
      nextBillingDate.setDate(currentBillingDate.getDate() - 7);
      break;

    case "Monthly":
      //set next billing period
      const nextMonthEnd = new Date(
        new Date(
          currentBillingDate.getFullYear(),
          currentBillingDate.getMonth(),
          1
        ).setDate(0)
      ); //jan 31, feb 28, etc..
      nextBillingDate = new Date(
        Math.min(
          new Date(
            currentBillingDate.getFullYear(),
            currentBillingDate.getMonth() - 1,
            billingDay
          ),
          nextMonthEnd
        )
      ); // when billing date > 30 or > 28 for feb get last day of the month
      break;
    case "Quarterly":
      //set next billing period
      const nextQuarterEnd = new Date(
        new Date(
          currentBillingDate.getFullYear(),
          currentBillingDate.getMonth() - 2,
          1
        ).setDate(0)
      ); //+4 months get and end date of the month
      nextBillingDate = new Date(
        Math.min(
          new Date(
            currentBillingDate.getFullYear(),
            currentBillingDate.getMonth() - 3,
            billingDay
          ),
          nextQuarterEnd
        )
      ); // when billing date > 30 or > 28 for feb get last day of the month
      break;
    case "Annual":
      //set next billing period
      const nextYearEnd = new Date(
        new Date(
          currentBillingDate.getFullYear(),
          currentBillingDate.getMonth() - 11,
          1
        ).setDate(0)
      ); //+4 months get and end date of the month
      nextBillingDate = new Date(
        Math.min(
          new Date(
            currentBillingDate.getFullYear(),
            currentBillingDate.getMonth() - 12,
            billingDay
          ),
          nextYearEnd
        )
      ); // for leap years
      break;
    default:
      //safety measure to get outside of loop
      nextBillingDate.setDate(new Date().getDate - 1);
      break;
  } // -----end switch

  return nextBillingDate;
}

export const convertArrayToObject = (array) => {
  return array?.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};

export const formatCurrency = (value) => {
  const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
  const parts = num.split("."); //split whole number and decimal
  parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
  return parts.join(".");
};
